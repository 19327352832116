import _ from 'underscore'
import Vue from 'vue'
import Dialog from '@/components/dialog'
import Form from '@/components/form'
import ApiService from '@/util/api.service'
import UserGroups from '@/components/UserGroups.vue'

const groups = async ({
  user
}) => {
  const Elem = Vue.extend(UserGroups)
  const _dialog = new Dialog({
    title: `Grupos ( ${user.name} )`,
    height: '800',
  })
  _dialog.open()
  _dialog.appendPage(Elem, { user })
}

const changePassword = async ({
  user,
  omitFields
}, scope) => {
  const form = new Form({
    schema: {
      password: {
        label: 'Nueva Contraseña',
        type: String,
        password: true
      },
      repeat_password: {
        label: 'Confirmar Contraseña',
        type: String,
        password: true
      }
    }
  })
  const dialog = new Dialog({
    title: 'Cambiar contraseña',
    subtitle: user.name,
    actions: [{
      help: 'Guardar',
      color: 'success',
      icon: 'mdi-content-save',
      action: () => {
        const item = form.getItem()
        form.fields.password.removeError()
        form.fields.repeat_password.removeError()
        if (form.hasErrors()) {
          return
        }
        if (item.password !== item.repeat_password) {
          form.fields.password.setError('La contraseña no coincide')
          form.fields.repeat_password.setError('La contraseña no coincide')
          return
        }
        let loader;
        if (scope) {
          loader = scope.$loading.show({
            loader: 'dots'
          })
        }
        ApiService({
          url: '/users/' + user.id,
          method: 'put',
          data: { ..._.omit(user, omitFields), password: item.password }
        }).then((res) => {
          dialog.close()
        }).catch((error) => {
          window.VMA.showError({ title: 'Error al cargar cambiar contraseña' })
          console.error(error)
        }).finally(() => {
          if (loader) {
            loader.hide()
          }
        })
      }
    }]
  })
  dialog.open()
  dialog.append(form)
}

const geofences = async ({
  user
}) => {
  window.VMA.loading(true)
  try {
    const _users = await ApiService({
      url: '/geofences',
      method: 'get',
      params: {
        all: true
      }
    })

    const _permissions = await ApiService({
      url: '/geofences',
      method: 'get',
      params: {
        userId: user.id
      }
    })

    const form = new Form({
      schema: _users.reduce((acc, geofence) => ({
        ...acc,
        [geofence.id]: {
          label: geofence.name,
          type: Boolean,
          changeOnLoad: false,
          onChange: async (val) => {
            if (val) {
              ApiService({
                url: '/permissions',
                method: 'post',
                data: { userId: user.id, geofenceId: geofence.id }
              }).then(() => {
              }).catch((err) => {
                console.error(err)
              })
            } else {
              ApiService({
                url: '/permissions',
                method: 'delete',
                data: { userId: user.id, geofenceId: geofence.id }
              }).then(() => {
              }).catch((err) => {
                console.error(err)
              })
            }
          }
        }
      }), {}),
      item: _permissions.reduce((acc, permission) => ({
        ...acc,
        [permission.id]: true
      }), {}),
    })
    const dialog = new Dialog({
      title: 'Geocercas',
      subtitle: user.name
    })
    dialog.open()
    dialog.append(form)
  } catch (err) {
    // ok
    console.error({ err })
    window.VMA.showError({ title: 'Error al cargar las geocercas' })
  } finally {
    window.VMA.loading(false)
  }
}

export default {
  groups,
  changePassword,
  geofences
}
