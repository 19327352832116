<template>
  <div
    v-if="loading"
    style="height: 100%; width: 100%; display: flex; align-items: center; justify-content: center;"
  >
    <v-progress-circular
      :size="50"
      color="primary"
      indeterminate
    ></v-progress-circular>
  </div>
  <div v-else>
    <v-tabs dark background-color="teal darken-3" show-arrows v-model="tab">
      <v-tabs-slider color="teal lighten-3"></v-tabs-slider>

      <v-tab v-for="(tab, index) of tabs" :key="index">
        {{ tab.title }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item v-for="(tab, index) of tabs" :key="index" :eager="true">
        <v-card flat>
          <v-card-title>
            <v-text-field
              flat
              label="Buscar"
              hide-details
              v-model="search"
              append-icon="mdi-magnify"
            ></v-text-field>
          </v-card-title>
          <v-card-text>
            <kalan-form ref="formPermisos" :schema="tab.schema" :item="tab.item"></kalan-form>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import _ from 'underscore'
import ApiService from '@/util/api.service'
import Form from '@/components/form/Form.vue'

export default {
  components: {
    'kalan-form': Form,
  },
  props: {
    user: Object,
  },
  data() {
    return {
      tab: 0,
      tabs: [],
      loading: true,
      search: '',
      titleMap: {
        bus: 'transporte',
        cameras: 'pmi',
        devices: 'gps',
        incidents: 'incidentes',
        lpr: 'lpr',
        sensor: 'botones'
      }
    };
  },
  created() {
    this.load();
  },
  methods: {
    async load() {
      this.loading = true
      try {
        // const _groups = await ApiService({
        //   url: '/groups?all=true',
        //   method: 'get',
        // })
        // const _permissions = await ApiService({
        //   url: '/groups',
        //   method: 'get',
        //   params: {
        //     userId: user.id
        //   }
        // })
        const groups = await ApiService({
          url: '/groups?all=true',
          method: 'get',
        });
        const permissions = await ApiService({
          url: '/groups',
          method: 'get',
          params: {
            userId: this.user.id
          }
        });

        const branchs = _.groupBy(groups, 'branch');
        const tabs = []
        Object.keys(branchs).forEach((branch) => {
          const _groups = branchs[branch];
          const form = {
            schema: _groups.reduce(
              (acc, group) => ({
                ...acc,
                [group.id]: {
                  label: group.name,
                  type: Boolean,
                  changeOnLoad: false,
                  onChange: async (val, fields) => {
                    fields[group.id].loading = true;
                    if (val) {
                      ApiService({
                        url: '/permissions',
                        method: 'post',
                        data: { userId: this.user.id, groupId: group.id }
                      }).then(() => {
                      }).catch((err) => {
                        console.error(err)
                      }).finally(() => {
                        fields[group.id].loading = false;
                      });
                    } else {
                      ApiService({
                        url: '/permissions',
                        method: 'delete',
                        data: { userId: this.user.id, groupId: group.id }
                      }).then(() => {
                      }).catch((err) => {
                        console.error(err)
                      }).finally(() => {
                        fields[group.id].loading = false;
                      });
                    }
                  }
                },
              }),
              {}
            ),
            item: permissions.reduce(
              (acc, permission) => ({
                ...acc,
                [permission.id]: true,
              }),
              {}
            ),
          };
          tabs.push({
            title: this.titleMap[branch] || 'Sin rama',
            ...form,
          });
        });
        this.tabs = _.sortBy(tabs, 'title')
      } catch (err) {
        // ok
        console.error(err);
        window.VMA.showError({ title: 'Error al cargar los grupos' });
      } finally {
        this.loading = false;
      }
    },
    applySearch() {
      this.$refs.formPermisos[this.tab].filterFields(this.search)
    }
  },
  watch: {
    tab() {
      this.applySearch()
    },
    search() {
      this.applySearch()
    }
  }
};
</script>

<style lang="css" scoped></style>
